import React from "react";
import CardCategory from "../../components/category/categoryCard";

import styles from "./productsPage.module.scss";
import Layout from "../../components/layout/layout";
import Widget from "../../components/widget/widget";
import WebshopContext from "../../providers/WebshopProvider";

import ProductGroupCard from "../../components/product/productGroupCard";

import { Link } from "gatsby";

let stateContext = null;

export default class productsPage extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    stateContext.setProducts(this.props.pageContext.categoryId);
  }

  getTitle = () => {
    const title = this.props.pageContext.seoTitle || "%%CATEGORY%%";
    return title.replace("%%CATEGORY%%", this.props.pageContext.categoryName);
  };

  getMetaDescription = () => {
    const description = this.props.pageContext.seoDescription || "%%CATEGORY%%";
    return description.replace(
      "%%CATEGORY%%",
      this.props.pageContext.categoryName
    );
  };

  render() {
    return (
      <Layout
        webshopfooterName={this.props.pageContext.webshopfooterName}
        webshopAddress={this.props.pageContext.webshopAddress}
        webshopZipCode={this.props.pageContext.webshopZipCode}
        webshopOpeningHours={this.props.pageContext.webshopOpeningHours}
        webshopCity={this.props.pageContext.webshopCity}
        footerLink={this.props.pageContext.webshopTermsLink}
        webshopName={this.props.pageContext.webshopName}
        logoUrl={this.props.pageContext.logoUrl}
        webshopPrivacyLink={this.props.pageContext.webshopPrivacyLink}
        webshopCompanyInfoLink={this.props.pageContext.webshopCompanyInfoLink}
        favicon={this.props.pageContext.favicon}
        buyerGroupId={this.props.pageContext.buyerGroupId}
        webshopLogo={this.props.pageContext.webshopLogo}
        title={this.getTitle()}
        metaDescription={this.getMetaDescription()}
        headers={this.props.pageContext.headers}
        footers={this.props.pageContext.footers}
      >
        <WebshopContext.Consumer>
          {webshop => {
            stateContext = webshop;
            return (
              <div>
                <div className={[styles.breadcrumbContainer]}>
                  <Link
                    to={"/altmetall-ankauf"}
                    className={[styles.breadcrumbItem]}
                  >
                    Altmetallankauf
                  </Link>
                  >
                  {this.props.pageContext.parentCategory === null ? null : (
                    <>
                      <Link
                        to={this.props.pageContext.categoryPath
                          .split("/")
                          .slice(0, -1)
                          .join("/")}
                        className={[
                          styles.breadcrumbItem,
                          styles.breadcrumbCategoryItem,
                          styles.breadcrumbSubCategoryItem,
                        ].join(" ")}
                      >
                        {this.props.pageContext.parentCategory.name}
                      </Link>
                      >
                    </>
                  )}
                  <span className={[styles.breadcrumbActive]}>
                    {this.props.pageContext.categoryName}
                  </span>
                </div>
                <h1
                  className={[
                    styles.pageHeader,
                    styles.pageTitle,
                    "pageTitle",
                    "blackColor",
                  ].join(" ")}
                >
                  {this.props.pageContext.categoryName} verkaufen
                </h1>
                <Widget
                  products={webshop.allProducts ? webshop.allProducts : []}
                  buyerId={this.props.pageContext.buyerGroupId}
                  currencySymbol={this.props.pageContext.currencySymbol}
                />
                <div className={styles.cardCategoryWrapper}>
                  <div className={styles.cardCategoryGroup}>
                    {this.props.pageContext.subCategory.length
                      ? this.props.pageContext.subCategory.map(value => {
                          return (
                            <CardCategory
                              key={value.id}
                              id={value.id}
                              name={value.name}
                              best_price={value.best_price}
                              image_id={value.images[0]._id}
                              slug={value.slug}
                              currencySymbol={
                                this.props.pageContext.currencySymbol
                              }
                            />
                          );
                        })
                      : null}
                  </div>
                </div>

                <ProductGroupCard
                  buyerId={this.props.pageContext.buyerGroupId}
                  allProducts={
                    this.props.pageContext.categoryId &&
                    webshop.products &&
                    webshop.products[this.props.pageContext.categoryId]
                      ? webshop.products[this.props.pageContext.categoryId]
                      : []
                  }
                  productDetailPage={false}
                  currencySymbol={this.props.pageContext.currencySymbol}
                />
              </div>
            );
          }}
        </WebshopContext.Consumer>
      </Layout>
    );
  }
}
